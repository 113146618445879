import { SubsCategoryClass } from "apps/subs_categories/models/subs_category";


export const SubsDocumentContentSections =  {
    description: "Descrizione",
    files: "Documenti",
    more_info: "Ulteriori informazioni"
}

export class SubsDocumentClass {

    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.description = data.description;
        this.category = new SubsCategoryClass(data.category_data);
        this.date_published = data.date_published;
        this.last_update = data.last_update;
        this.content = data.content;
        this.user = data.user;
    }   
}