import { Payment } from "admin/payments/models/payment";
import env from "env";

export const ModulesIDMapping = {
    domandaIscrizione: env.DOMANDA_ISCRIZIONE,
    domandaTrasferimento: env.DOMANDA_TRASFERIMENTO,
    domandaCancellazione: env.DOMANDA_CANCELLAZIONE,
    domandaRinnovo: env.DOMANDA_RINNOVO,

    // domandaTest: "4003754f-015a-4f36-bfde-165a4ec10499"
}

export class RequestStatusUpdate {

    constructor(data){
        this.status =       data.status;
        this.user =         data.user;
        this.created =      new Date(data.created);
        this.note =         data.note ?? "";
    }
}


export class RequestDataUpdate {

    constructor(data){
        this.id =           data.id;
        this.values =       data.values;
        this.user =         data.user;
        this.created =      new Date(data.created);
    }
}


export class Request {

    get values() {
        return this.dataUpdates[0].values;
    } 

    get lastUpdate() {
        return new Date(this.dataUpdates[0].created);
    } 

    get status() {
        return this.statusUpdates[0].status;
    } 

    get isEditable() {
        return this.status === 'draft' || this.status === 'waiting_integration';
    }

    get sent() {
        var sent = null;
        this.statusUpdates.forEach((statusUpdate) => {
            if (statusUpdate.status === 'waiting_review') {
                sent = statusUpdate.created;
            }
        });
        return sent;
    }

    get reviewed() {
        var reviewed = null;
        this.statusUpdates.forEach((statusUpdate) => {
            if (statusUpdate.status === 'under_review') {
                reviewed = statusUpdate.created;
            }
        });
        return reviewed;
    }

    get expected_completion() {
        if (this.sent != null && this.module.service.completion_days > 0) {
            var expected_completion = new Date(this.sent);
            expected_completion.setDate(expected_completion.getDate() + this.module.service.completion_days);
            return expected_completion;
        }
        return null;
    }

    constructor(data){
        this.id =           data.id;

        this.user =         data.user;
        this.created =      new Date(data.created);
        this.modified =     new Date(data.modified);

        this.protocolSent       = data.protocol_sent ? new Date(data.protocol_sent) : undefined;
        this.protocolSubject    = data.protocol_subject;
        this.protocolId         = data.protocol_id;
        this.protocolAssigned   = data.protocol_assigned ? new Date(data.protocol_assigned) : undefined;
        
        this.dataUpdates    = data.request_data_update?.map((data_update) => new RequestDataUpdate(data_update)) || [];
        this.statusUpdates  = data.request_status_update?.map((status_update) => new RequestStatusUpdate(status_update)) || [];
        this.payments       = data.payment?.map((payment) => new Payment(payment)) || [];

        this.module =       data.module;
    }
}
