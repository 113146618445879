import { ActaUser } from "pa_kit/apps/user/models/user";

export class SubsActivityClass {    
  
    constructor(data){
        this.id = data.id;
        this.user = new ActaUser(data.user_data);
        this.description = data.description;
        this.date = data.date;
    }
}