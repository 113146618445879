import ApiService from '../../../services/api';

export class SubsCategoryClass {    
  
    constructor(data){
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.alt_link = data.alt_link;
        this.description = data.description;
        this.is_news = data.is_news;
        this.is_document = data.is_document;
        this.parent = data.parent_data || '';
    }

    hasParent() {
        return this.parent?.slug && this.parent?.slug !== '';
    }

    async getBreadcrumb() {
        let breadcrumb = [];        
        if(this.hasParent()) {
            let breadcrumbResult = await ApiService.getSubsCategory(this.parent?.slug);
            let parent = new SubsCategoryClass(breadcrumbResult);
            if(parent.hasParent()) {
                breadcrumb = await parent.getBreadcrumb();
            } else {
                breadcrumb.push([parent.name, parent.slug]);
            }                    
        }       

        breadcrumb.push([this.name, this.slug]);        

        return breadcrumb;
    }

}
