/* eslint-disable array-callback-return */
// @ts-nocheck
import React, { useContext, Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ActaAuthContext } from "./pa_kit/auth";
import { SubscriberAuthContext } from "services/auth_subscribers";
import App from './app';


const AppRoutes = () => {

    const auth = useContext(ActaAuthContext);
    const subscriber_auth = useContext(SubscriberAuthContext);

    const theme = auth.theme;  

    const defaultTheme = {
        "--color-primary": theme.primary_color,
        "--color-secondary": theme.secondary_color,
        "--color-tertiary": theme.tertiary_color,
        "--color-quaternary": theme.quaternary_color,
    }    

    const applyTheme = (theme) => {
        Object.keys(theme).map((key) => {
            const value = theme[key];
            document.documentElement.style.setProperty(key, value);
        });
    };
    
    applyTheme(defaultTheme);

    const isActagovWebsiteEditor = auth.user && auth.user.isActagovWebsiteEditor();
    const isActagovContentEditor = auth.user && auth.user.isActagovContentEditor();

    const isSubscriber = subscriber_auth.isSubscriber;

    
    const PageSubscribersArea = lazy(() => import('apps/subscribers/components/page_subscribers_area')); 
    const PageSubscriberProfile = lazy(() => import('apps/subscribers/components/page_subscriber_profile')); 
    const PageSubsNews = lazy(() => import('apps/subs_news/components/page_news'));
    const PageSubsNewsArticle = lazy(() => import('apps/subs_news/components/page_news_article'));
    const PageSubsNewsCategory = lazy(() => import('apps/subs_news/components/page_news_category'));
    const PageSubsDocuments = lazy(() => import('apps/subs_documents/components/page_documents'));
    const PageSubsDocumentsCategory = lazy(() => import('apps/subs_documents/components/page_documents_category'));
    const PageSubsDocumentSingle = lazy(() => import('apps/subs_documents/components/page_document_single'));

    const Dashboard = lazy(() => import('admin/dashboard/dashboard'));
    const Main = lazy(() => import('admin/dashboard/main'));
    const CreateSubsNews = lazy(() => import('admin/subs_news/create_news'));
    const EditSubsNews = lazy(() => import('admin/subs_news/edit_news'));
    const SubsNewsList = lazy(() => import('admin/subs_news/news_list'));
    const CreateSubsCategory = lazy(() => import('admin/subs_categories/create_category'));
    const EditSubsCategory = lazy(() => import('admin/subs_categories/edit_category'));
    const SubsCategoriesList = lazy(() => import('admin/subs_categories/categories_list'));
    const SubsActivitiesList = lazy(() => import('admin/subs_activities/activities_list'));
    const SubscribersList = lazy(() => import('admin/subscribers/subscribers_list'));
    const CreateSubscriber = lazy(() => import('admin/subscribers/create_subscriber'));
    const EditSubscriber = lazy(() => import('admin/subscribers/edit_subscriber'));
    const CreateSubsDocument = lazy(() => import('admin/subs_documents/create_document'));    
    const EditSubsDocument = lazy(() => import('admin/subs_documents/edit_document'));
    const SubsDocumentsList = lazy(() => import('admin/subs_documents/documents_list'));
    const RequestsList = lazy(() => import('admin/requests/components/requests_list'));
    const ManageRequests = lazy(() => import('admin/requests/components/manage_requests'));



    if(auth.isLoading) return <div></div>;
    if(subscriber_auth.isSubscriberLoading) return <div></div>;
    return <>
        <BrowserRouter>
            <Suspense fallback={<div></div>}>
                <Routes>
                    <Route path="/" element={<App />} >
                        <Route path="" element={<PageSubscribersArea />}></Route>
                        <Route path="profilo/" element={<PageSubscriberProfile />}></Route>
                        <Route path="novita" element={isSubscriber ? <PageSubsNews /> : <Navigate to="/" />}></Route>
                        <Route path="novita/:categorySlug/" element={isSubscriber ? <PageSubsNewsCategory /> : <Navigate to="/" />} />
                        <Route path="novita/:categorySlug/:newsSlug" element={isSubscriber ? <PageSubsNewsArticle /> : <Navigate to="/" />} />
                        <Route path="documenti-e-dati/" element={isSubscriber ? <PageSubsDocuments /> : <Navigate to="/" />} />
                        <Route path="documenti-e-dati/documento/:documentSlug" element={isSubscriber ? <PageSubsDocumentSingle /> : <Navigate to="/" />} />
                        <Route path="documenti-e-dati/:categorySlug" element={isSubscriber ? <PageSubsDocumentsCategory /> : <Navigate to="/" />} /> 
                    </Route>

                    <Route path="/admin" element={(isActagovWebsiteEditor || isActagovContentEditor) ? <Dashboard /> : <Navigate to="/" />} >
                        <Route path="" element={<Main />} ></Route>
                        <Route path="news" element={isActagovContentEditor ? <SubsNewsList /> : <Navigate to="/" />} ></Route>
                        <Route path="create-news" element={isActagovContentEditor ? <CreateSubsNews /> : <Navigate to="/" />}></Route>
                        <Route path="edit-news/:newsSlug" element={isActagovContentEditor ? <EditSubsNews /> : <Navigate to="/" />}></Route>
                        <Route path="categories" element={isActagovWebsiteEditor ? <SubsCategoriesList /> : <Navigate to="/" />} ></Route>
                        <Route path="create-category" element={isActagovWebsiteEditor ? <CreateSubsCategory /> : <Navigate to="/" />} ></Route>
                        <Route path="edit-category/:categorySlug" element={isActagovWebsiteEditor ? <EditSubsCategory /> : <Navigate to="/" />} ></Route>
                        <Route path="activities" element={isActagovWebsiteEditor ? <SubsActivitiesList /> : <Navigate to="/" />} ></Route>
                        <Route path="subscribers" element={isActagovWebsiteEditor ? <SubscribersList /> : <Navigate to="/" />} ></Route>
                        <Route path="create-subscriber" element={isActagovWebsiteEditor ? <CreateSubscriber /> : <Navigate to="/" />} ></Route>
                        <Route path="edit-subscriber/:subFiscalCode" element={<EditSubscriber />}></Route>
                        <Route path="documents" element={isActagovContentEditor ? <SubsDocumentsList /> : <Navigate to="/" />} ></Route>
                        <Route path="create-document" element={isActagovContentEditor ? <CreateSubsDocument /> : <Navigate to="/" />}></Route>
                        <Route path="edit-document/:documentSlug" element={isActagovContentEditor ? <EditSubsDocument /> : <Navigate to="/" />}></Route>
                        <Route path="requests/:type" element={isActagovWebsiteEditor ? <RequestsList /> : <Navigate to="/" /> }></Route>
                        <Route path="manage-requests/:type/:requestID" element={isActagovWebsiteEditor ? <ManageRequests /> : <Navigate to="/" /> }></Route>
                    </Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    </>;
}

export default AppRoutes;
