import { SubsCategoryClass } from '../../subs_categories/models/subs_category';

export const SubsNewsContentSections =  {
    description: "Descrizione",
    attachments: "Documenti",
    more_info: "Ulteriori informazioni"
}

export class SubsNewsClass {    
  
    constructor(data){
        this.id = data.id;
        this.title = data.title;
        this.slug = data.slug;
        this.image = data.image;
        this.meta_description = data.meta_description;
        this.image_description = data.image_description;
        this.content = data.content;
        this.category = new SubsCategoryClass(data.category_data);
        this.date_news = data.date_news;
        this.date_published = data.date_published;
        this.is_sticky = data.is_sticky;
        this.state = data.state;
        this.user = data.user;
        this.last_update = data.last_update;
    }
}
