import { ActaUser } from 'pa_kit/apps/user/models/user.js';

export class Subscriber extends ActaUser {

    constructor(userData, data){
        super(userData);

        this.n_sub = data.n_sub;
        this.fiscal_code = data.fiscal_code;
        this.date_start_sub = data.date_start_sub;
        this.date_expire_sub = data.date_expire_sub;
        this.state = data.state;
    }
    
}

export class SubscriberAdminView {

    constructor(data) {
        this.id = data.id;
        this.n_sub = data.n_sub;
        this.first_name = data.first_name;
        this.last_name = data.last_name;
        this.fiscal_code = data.fiscal_code;
        this.date_start_sub = data.date_start_sub;
        this.date_expire_sub = data.date_expire_sub;
        this.birth_date = data.birth_date;
        this.state = data.state;
    }
}