export class Payment {

    get canBePaid() {
        return this.status === 'notPaid';
    } 
    get isPaid() {
        return this.status === 'paid';
    } 
    get isCanceled() {
        return this.status === 'cancelled';
    }

    constructor(data){

        this.id = data.id;
        this.iuv = data.iuv;
        this.reason = data.reason;
        this.note = data.note;
        this.user = data.user;
        this.created =      new Date(data.created);

        this.amount = data.position.amount;
        this.status = data.position.status;

        if(data.position.paymentDate){
            this.paymentDate = new Date(data.position.paymentDate);
        }
        if(data.position.expireDate){
            this.expireDate = new Date(data.position.expireDate);
        }
        if(data.canceled){
            this.cancelDate = new Date(data.canceled);
            this.status = "cancelled"
        }
        this.method = data.position.method;
    }
}
