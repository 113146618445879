/* eslint-disable react-hooks/exhaustive-deps */
import http, { httpServices } from './http';
import { SubsNewsClass } from 'apps/subs_news/models/subs_news';
import { SubsCategoryClass } from 'apps/subs_categories/models/subs_category';
import { SubsActivityClass } from 'apps/subs_activities/models/subs_activity';
import { SubscriberAdminView } from 'apps/subscribers/models/subscribers';
import { SubsDocumentClass } from 'apps/subs_documents/models/subs_document';
import { Request } from 'admin/requests/models/request';
import { PaymentClass } from 'apps/payments/models/payment';


class ApiService {

    // Check Sub
    async checkSub(fiscalCode) {
        let result = await http.get('subscriber/' + fiscalCode);
        return result.data;
    }

    // Get Subs Category
    async getSubsCategory(slug) {
        let result = await http.get('subs_categories/' + slug);
        return result.data; 
    }

    // Get Subs CategoryList
    async getSubsCategoryList() {
        let result = await http.get('subs_categories/');
        return result.data.map((data) => new SubsCategoryClass(data)); 
    }

    // Create Subs Category
    async createSubsCategory(formData) {
        let result = await http.post('subs_categories/', {...formData });
        return result;
    }

    // Edit Subs Category
    async editSubsCategory(slug, formData) {
        let result = await http.patch('subs_categories/' + slug + '/', {...formData });
        return result;
    }

    // Delete Subs Category
    async deleteSubsCategory(slug) {
        let result = await http.delete('subs_categories/' + slug + '/');
        return result;
    }

    // Get Subs News
    async getSubsNews(slug) {        
        let result = await http.get('subs_news/' + slug);       
        return result.data;
    }

    // Get Subs News List
    async getSubsNewsList(category) {  
        let result; 
        if(!category){
            result = await http.get('subs_news/'); 
        } else {
            result = await http.get('subs_news/category/' + category); 
        }
              
        return result.data.map((data) => new SubsNewsClass(data));
    }

    // Get Sticky Subs News
    async getStickySubsNews() {        
        let result = await http.get('subs_news/sticky');       
        return result.data[0];
    }

    // Get Latest News
    async getLatestSubsNews() {        
        let result = await http.get('subs_news/latest');       
        return result.data.map((data) => new SubsNewsClass(data));
    }

    // Get Latest Subs News Admin
    async getLatestSubsNewsAdmin() {        
        let result = await http.get('subs_news/latest_admin');       
        return result.data.map((data) => new SubsNewsClass(data));
    }

    // Get Latest Subs News By Category
    async getLatestSubsNewsByCategory(category) {        
        let result = await http.get('subs_news/latest_by_category/' + category);       
        return result.data.map((data) => new SubsNewsClass(data));
    }

    // Create Subs News
    async createSubsNews(formData) {
        let result = await http.post('subs_news/', {...formData });
        return result;
    }

    // Edit Subs News
    async editSubsNews(slug, formData) {
        let result = await http.patch('subs_news/' + slug + '/', {...formData });
        return result;
    }

    // Delete Subs News
    async deleteSubsNews(slug) {
        let result = await http.delete('subs_news/' + slug + '/');
        return result;
    }

    // Upload File
    async uploadFile(file, source) {
        const formData = new FormData();

        switch(source) {
            case "tinymce_news":
                formData.append("source", "tinymce_news");
                break;

            case "tinymce_event":
                formData.append("source", "tinymce_event");
                break;

            case "tinymce_text":
                formData.append("source", "tinymce_text");
                break;

            case "tinymce_category":
                formData.append("source", "tinymce_category");
                break;

            // case "tinymce_tag":
            //     formData.append("source", "tinymce_tag");
            //     break;

            case "tinymce_custom_widget":
                formData.append("source", "tinymce_custom_widget");
                break;

            default:
                formData.append("source", "standard");
        }
        
        formData.append("file", file);

        const filenameHeaderValue = 'inline;filename=' + encodeURIComponent(file.name);

        const result = await http.put('/file/upload/', formData, 
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Content-Disposition': filenameHeaderValue,
                    "Access-Control-Allow-Origin": "*"
                }
            }
        );
        return result.data;
    }

    // Get Image List
    async getImageList(folder) {
        let result = await http.get(`image-list/` + folder);
        return result.data;
    }

    // Get Subs Activities
    async getSubsActivities() {
        let result = await http.get('subs_activities/');       
        return result.data.map((data) => new SubsActivityClass(data));
    }  

    // Get Subscribers List
    async getSubscribersList() {
        let result = await http.get('subscribers/');       
        return result.data.map((data) => new SubscriberAdminView(data));
    }

    // Get Subscriber
    async getSubscriber(fiscal_code) {        
        let result = await http.get('subscribers/' + fiscal_code);       
        return result.data;
    }

    // Create Subscriber
    async createSubscriber(formData) {
        let result = await http.post('subscribers/', {...formData });
        return result;
    }

    // Edit Subs Category
    async editSubscriber(fiscal_code, formData) {
        let result = await http.patch('subscribers/' + fiscal_code + '/', {...formData });
        return result;
    }

    // Delete Subscriber
    async deleteSubscriber(fiscal_code) {
        let result = await http.delete('subscribers/' + fiscal_code + '/');
        return result;
    }

    // Get Subs Documents
    async getSubsDocuments() {
        let result = await http.get('subs_documents/');       
        return result.data.map((data) => new SubsDocumentClass(data));
    }

    // Get Subs Document
    async getSubsDocument(slug) {
        let result = await http.get('subs_documents/' + slug);       
        return result.data;
    }

    //Get Latest Subs Documents Admin
    async getLatestSubsDocumentsAdmin() {        
        let result = await http.get('subs_documents/latest_admin/');       
        return result.data.map((data) => new SubsDocumentClass(data));
    }

    // Get Latest Subs Documents By Category
    async getLatestSubsDocumentsByCategory(category) {        
        let result = await http.get('subs_documents/latest_by_category/' + category);       
        return result.data.map((data) => new SubsDocumentClass(data));
    }

    // Get Subs Documents By Category
    async getSubsDocumentsByCategory(category) {        
        let result = await http.get('subs_documents/documents_by_category/' + category);       
        return result.data.map((data) => new SubsDocumentClass(data));
    }

    // Create Subs Document
    async createSubsDocument(formData) {
        let result = await http.post('subs_documents/', {...formData });
        return result;
    }

    // Edit Subs Document
    async editSubsDocument(slug, formData) {
        let result = await http.patch('subs_documents/' + slug + '/', {...formData });
        return result;
    }

    // Delete Subs Document
    async deleteSubsDocument(slug) {
        let result = await http.delete('subs_documents/' + slug + '/');
        return result;
    }

    // Get Requests By Module
    async getRequestsByModule(moduleID) {
        let result = await httpServices.get('requests/?module=' + moduleID);       
        return result.data.map((data) => new Request(data));
    }

    // Get Request 
    async getRequest(requestID) {
        let result = await httpServices.get('requests/' + requestID);       
        return result.data;
    }

    // Get Payment History
    async getPaymentHistory(fiscalCode) {
        let result = await http.get('payments/' + fiscalCode);
        return result.data.map((data) => new PaymentClass(data));
    }

    // Get Request 
    async updateRequestStatus(requestFormData) {
        let result = await httpServices.post('request_status/', {...requestFormData });       
        return result;
    }

}

export default new ApiService();
