// @ts-nocheck
import React, { useEffect, useState, useContext } from 'react';
import { createContext } from 'react';
import { ActaAuthContext } from 'pa_kit/auth.js';
import ApiService from 'services/api';
import { Subscriber } from 'apps/subscribers/models/subscribers';

const SubscriberAuthContext = createContext();

export default function SubscriberAuthProvider({ children }) {

    const auth = useContext(ActaAuthContext);
    const actagov_config = auth.organization.active_licenses?.services?.actagov;

    const [ subscriber, setSubscriber ] = useState();
    const [ isSubscriberLoading, setIsSubscriberLoading ] = useState(true);
    const [ isSubscriber, setIsSubscriber ] = useState(false);

    const user = auth.user;

    useEffect(() => {           
        const checkSub = async () => {
            if (auth.user) {
                if(!auth.user.isActagovWebsiteEditor() && !auth.user.isActagovContentEditor()) {
                    try {
                        const data = await ApiService.checkSub(user.attributes.tax_code);
                        setSubscriber(new Subscriber(user, data));  
                        setIsSubscriber(true);
                        setIsSubscriberLoading(false);
                    } catch {
                        // window.location.href = actagov_config?.frontend_url;
                        // return <></>;
                        setIsSubscriberLoading(false);
                    }  
                } else {
                    try {
                        const data = await ApiService.checkSub(user.attributes.tax_code);
                        setSubscriber(new Subscriber(user, data));  
                        setIsSubscriber(true);
                    } catch {
                        setSubscriber(new Subscriber(user, ""));  
                        setIsSubscriber(true);                        
                    }
                    setIsSubscriberLoading(false);
                }
            }else {
                window.location.href = actagov_config?.frontend_url;
                return <></>;
            }
        };

        if (auth && !auth.isLoading) {
            checkSub();
        }
    }, [auth.isLoading]);
    

    const subscriberAuth = {
        subscriber,
        isSubscriberLoading,
        isSubscriber
    };    

    return (
        <SubscriberAuthContext.Provider value={subscriberAuth}>            
            {children}            
        </SubscriberAuthContext.Provider>
    );
}

export { SubscriberAuthContext }